import './App.css';
import TikTokStats from './scrap/scrap';

function App() {
  return (
    <div className="App">
      <div className='containerTikTokStats'>
        <TikTokStats username="monkeyki.sbranle" />
        <TikTokStats username="jacquessapin5" />
        <TikTokStats username="maxibrainrot" />
        <TikTokStats username="apagnan313" />
        <TikTokStats username="klaiveur" />
        <TikTokStats username="michaelscor16" />
      </div>
    </div>
  );
}

export default App;
