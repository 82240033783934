import React, { useState, useEffect } from "react";
import './scrap.css';

const TikTokStats = ({ username }) => {
  const [stats, setStats] = useState({ followers: null, likes: null });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTikTokData = async () => {
      if (!username) {
        setError("Le nom d'utilisateur est requis.");
        return;
      }

      try {
        const response = await fetch(`http://louishuguet.fr:8000/proxy?username=${encodeURIComponent(username)}`);

        if (!response.ok) {
          throw new Error('Impossible de récupérer les données.');
        }

        const data = await response.json();

        if (data.followers && data.likes) {
          setStats({ followers: data.followers, likes: data.likes });
        } else {
          setError("Impossible de trouver les données dans la réponse.");
        }
      } catch (err) {
        console.error(err);
        setError("Impossible de récupérer les données.");
      }
    };

    fetchTikTokData();
  }, [username]);

  const handlePress = () => {
    window.open(`https://www.tiktok.com/@${username}`);
  };

  return (
      <button className="container" onClick={handlePress}>
        <div className="usernameContainer">
          <h1 className="username">@{username}</h1>
        </div>
        {error ? (
            <p style={{ color: "red" }}>{error}</p>
        ) : (
            <div className="statContainer">
                <div className="followContainer">
                  <p><strong>Abonnés :</strong> {stats.followers}</p>
                </div>
                <div className="likeContainer">
                  <p><strong>Likes :</strong> {stats.likes}</p>
                </div>
            </div>
        )}
      </button>
  );
};

export default TikTokStats;
